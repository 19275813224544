<template>
  <app-select :items="listItems" item-text="name" item-value="_id" :value="value" @input="updateValue($event)"  v-bind="$attrs"
              v-on="$listeners" :rules="rules"
              label="Select Organisation"/>
</template>

<script>
import {mapState} from "vuex";
import AppSelect from "@/components/app/Select";

export default {
  name: "GroupPicker",
  components: {AppSelect},
  props: {
    value: {
      type: String,
    },
    items:{
      type: Array
    },
    rules:{
      type: Array,
      default:() => []
    }
  },
  data() {
    return {
      listItems:[]
    }
  },
  computed: {
    ...mapState("group", ["groups"])
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value)
    }
  },
  mounted(){
    this.listItems = this.items ? this.items : this.groups
  }
}
</script>

<style scoped>

</style>