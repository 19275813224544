<template>
  <div>
    <h1 class="text-h4">Manage Users</h1>
    <v-divider class="my-2"/>
    <users-table/>
  </div>
</template>

<script>
import UsersTable from "@/components/shared/UsersTable";
export default {
name: "UsersPage",
  components: {UsersTable}
}
</script>

<style scoped>

</style>