<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="usersList"
        :sort-by="['name']"
        :sort-desc="[false]"
        :search="search"
        class="elevation-1 mt-5"
        dense
        item-key="_id"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">

          <v-spacer></v-spacer>
          <new-user-modal/>
        </v-toolbar>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon small :color="item.active ? 'success': undefined">
          mdi-{{item.active ? 'check-circle':'circle-outline'}}
        </v-icon>
      </template>
      <template v-slot:item.roles="{ item }">
        <div class="text-body-2" v-for="(role,i) in item.roles" :key="i">{{ role }}</div>
      </template>
<!--      <template v-slot:item.passwordExpired="{ item }">-->
<!--        <v-simple-checkbox v-model="item.passwordExpired" disabled></v-simple-checkbox>-->
<!--      </template>-->
      <template v-slot:item.resetPasswordExpires="{ item }">
        {{ item.resetPasswordExpires | moment('LLL') }}
      </template>
      <template v-slot:item.resetPasswordToken="{ item }">
        <v-btn text @click="copyToClipboard(`https://www.myflyingmanager.aero/verify?token=${item.resetPasswordToken}`)" v-if="item.resetPasswordToken && item.resetPasswordToken.length>0">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn fab small :color="item.active ? 'error':'success' " @click="changeAccountStatus(item._id, item.username)"
               :disabled="item._id === loggedInUser._id">
          <v-icon>{{ item.active ? 'mdi-account-lock' : 'mdi-account' }}</v-icon>
        </v-btn>
        <v-btn fab small color="primary" @click="resetUserPassword(item.username)" :disabled="!item.active">
          <v-icon>mdi-account-key</v-icon>
        </v-btn>
        <v-btn fab small color="primary" @click="edit(item)" disabled>
          <v-icon>mdi-account-edit</v-icon>
        </v-btn>
        <v-btn fab small color="primary" disabled>
          <v-icon>mdi-account-remove</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {UserHttp} from "@/http/UserHttp";
import NewUserModal from "@/components/modals/NewUserModal";


export default {
  name: "UsersTable",
  components: {NewUserModal},
  data: () => {
    return {
      search: '',
      isLoading: false,
      headers: [
        {text: "Name", value: "name"},
        {text: "Username", value: "username"},
        {text: "Can login?", value: "active"},
        {text: "Permissions", value: "roles"},
        // {text: "Password Expired", value: "passwordExpired"},
        {text: "Token", value: "resetPasswordToken"},
        {text: "PW Reset Expired on", value: "resetPasswordExpires"},

        {text: "Actions", value: "actions", sortable: false}
      ]
    };
  },
  async mounted() {
    await this.setUsersList()
  },
  filters: {},

  computed: {
    ...mapState("user", ["usersList"]),
    ...mapState("person", [
      "persons", "activePerson", "manageCreateNewMemberDialog", "manageViewPersonDialog"
    ]),
    ...mapState("group", ["activeGroup"]),
    ...mapState("app", ["loggedInUser"])
  },

  watch: {},
  methods: {
    ...mapActions("user", ["setUsersList", "changeAccountStatus"]),
    ...mapActions("person", [
      "setPersons", "setActivePerson", "setManageViewPersonDialog", "setManageCreateNewMemberDialog"
    ]),
    async copyToClipboard(s) {
      await navigator.clipboard.writeText(s);
      // this.setToast({color: 'success', text: 'password copied to clipboard', show: true})
    },

    async changeAccountStatus(accountId, username) {
      if(confirm(`are you sure you want to change the account status User: ${ username}?`))
      await UserHttp.changeAccountStatus(accountId,true)
      await this.setUsersList()
    },

    async resetUserPassword(accountUsername) {
      if(confirm(`Are you sure you want to send a recovery link to User: ${ accountUsername}?`))
      await UserHttp.sendRecoveryLink(accountUsername)
      await this.setUsersList()
    },


    async initialize() {
      this.isLoading = true;
      await this.setUsersList()
      this.isLoading = false;
    },
    async createNewPerson() {
      await this.setManageCreateNewMemberDialog(true);
    },
    async view(item) {
      await this.setActivePerson(item);
      await this.setManageViewPersonDialog(true);
    },
    async edit(item) {
      await this.setActivePerson(item);
      await this.setManageCreateNewMemberDialog(true);
    },
  }
};
</script>