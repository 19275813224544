<template>
  <div>
    <h1 class="text-h4">Manage Persons</h1>
    <v-divider class="my-2"/>
    <persons-table/>
  </div>
</template>

<script>
import PersonsTable from "@/components/shared/PersonsTable";

export default {
  name: "PersonPage",
  components: {PersonsTable}
}
</script>

<style scoped>

</style>