<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>

    <!--        :headers="headers"-->
    <v-data-table :items="persons"
                  :headers="headers"

                  :sort-by="['name']"
                  :sort-desc="[false]"
                  :search="search"
                  class="elevation-1 mt-5"
                  dense
                  item-key="_id"
    >
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
      <template v-slot:item.isChecker="{ item }">
        <v-icon small :color="item.isChecker ? 'success': undefined">
          mdi-{{ item.isChecker ? 'check-circle' : 'circle-outline' }}
        </v-icon>
      </template>
      <!--      <template v-slot:item.roles="{ item }">-->
      <!--        <div class="text-body-2" v-for="(role,i) in item.roles" :key="i">{{ role }}</div>-->
      <!--      </template>-->
      <!--&lt;!&ndash;      <template v-slot:item.passwordExpired="{ item }">&ndash;&gt;-->
      <!--&lt;!&ndash;        <v-simple-checkbox v-model="item.passwordExpired" disabled></v-simple-checkbox>&ndash;&gt;-->
      <!--&lt;!&ndash;      </template>&ndash;&gt;-->
      <!--      <template v-slot:item.resetPasswordExpires="{ item }">-->
      <!--        {{ item.resetPasswordExpires | moment('LLL') }}-->
      <!--      </template>-->

      <template v-slot:item.actions="{ item }">
        <v-btn fab small :color="item.active ? 'error':'success' "
               @click="changePersonFlightCheckerStatus(item._id, item.name)"
               :disabled="item._id === loggedInUser._id">
          <v-icon>{{ item.active ? 'mdi-account-lock' : 'mdi-account' }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {PersonHttp} from "@/http/PersonHttp";

export default {
  name: "PersonsTable",
  data: () => {
    return {
      search: '',
      isLoading: false,
      listOfInstructorsTypes: [],
      headers: [
        {text: "Name", value: "name"},
        {text: "is LPC Checker", value: "isChecker"},
        {text: "Actions", value: "actions", sortable: false}
      ]
    };
  },
  computed: {

    ...mapState("user", ["usersList","groupMembership"]),
    ...mapState("person", [
      "persons", "activePerson", "manageCreateNewMemberDialog", "manageViewPersonDialog"
    ]),
    ...mapState("group", ["activeGroup"]),
    ...mapState("app", ["loggedInUser"]),

  },

  watch: {},
  methods: {
    ...mapActions("licence", ["setAllLicenceEndorsementTypes"]),
    ...mapActions("user", ["setUsersList", "changeAccountStatus"]),
    ...mapActions("person", [
      "setPersons", "setActivePerson", "setManageViewPersonDialog", "setManageCreateNewMemberDialog"
    ]),

    async initialize() {
      this.isLoading = true;
      this.isLoading = false;
    },

    async changePersonFlightCheckerStatus(Id, name) {
      if (confirm(`are you sure you want to change the flight Checker Status for : ${name}?`))
        await PersonHttp.updatePersonFlightCheckerStatus(Id)
      await this.setPersons(this.groupMembership[0]._id)
    },
  }
};
</script>