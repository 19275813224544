<template>
  <v-dialog max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="grey" v-bind="attrs" v-on="on">
        <v-icon left>mdi-account-plus</v-icon>
        <span>New User</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h2>Add a New User</h2>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form">
          <v-text-field label="Name" v-model="name" prepend-icon="mdi-account"/>
          <v-text-field label="E-mail" v-model="email" prepend-icon="mdi-email"/>
          <group-picker v-model="group"/>
          <v-btn text
                 class="success mx-0 mt-3"
                 @click="submit()"
                 :loading="isLoading"
                 :disabled="isLoading">Add Member
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {UserHttp} from "../../http/UserHttp";
import GroupPicker from "@/components/shared/GroupPicker";

export default {
  name: "NewUserModal",
  components: {GroupPicker},
  data() {
    return {
      isLoading: false,
      name: '',
      group: null,
      nameRules: [
        v => !!v || 'Name is required',
        v => /[a-zA-Z]+\s+[a-zA-Z]+/g.test(v) || 'Firstname and Lastname is required',
        v => v.length >= 3 || 'Minimum Length is 3 characters'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  computed: {
    ...mapState("person", ["persons", "activePerson"]),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("user", ["groupMembership"]),

  },
  methods: {
    ...mapActions("person", ["setPersons", "setActivePerson"]),
    ...mapActions("app", ["setToast"]),
    async submit() {
      if (this.$refs.form.validate()) {
        await this.setActivePerson({});
        this.activePerson.name = this.name
        this.activePerson.email = this.email
        // Set the group the person is part of as the person doing the data entry. (only does the first group)
        this.activePerson.group = this.group
        this.activePerson.source = 'admin'
        try {
          await UserHttp.createAccount(this.activePerson)
          await this.setPersons(this.groupMembership[0]._id)
          await this.setActivePerson(null)
          this.setToast({color: 'success', text: 'New user account created successfully', show: true});
          this.$refs.form.reset()

        } catch (err) {
          if (err.response) {
            this.setToast({color: 'error', text: err.response.data.message, show: true});
          } else {
            console.log(err)
          }
        } finally {
          this.isLoading = false;
        }
      }

    }
  }
}

</script>

<style scoped>

</style>