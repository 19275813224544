import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"grey"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_c('span',[_vm._v("New User")])],1)]}}])},[_c(VCard,[_c(VCardTitle,[_c('h2',[_vm._v("Add a New User")])]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"px-3"},[_c(VTextField,{attrs:{"label":"Name","prepend-icon":"mdi-account"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{attrs:{"label":"E-mail","prepend-icon":"mdi-email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('group-picker',{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}}),_c(VBtn,{staticClass:"success mx-0 mt-3",attrs:{"text":"","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Add Member ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }