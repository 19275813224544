import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1 mt-5",attrs:{"items":_vm.persons,"headers":_vm.headers,"sort-by":['name'],"sort-desc":[false],"search":_vm.search,"dense":"","item-key":"_id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true},{key:"item.isChecker",fn:function({ item }){return [_c(VIcon,{attrs:{"small":"","color":item.isChecker ? 'success': undefined}},[_vm._v(" mdi-"+_vm._s(item.isChecker ? 'check-circle' : 'circle-outline')+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"fab":"","small":"","color":item.active ? 'error':'success',"disabled":item._id === _vm.loggedInUser._id},on:{"click":function($event){return _vm.changePersonFlightCheckerStatus(item._id, item.name)}}},[_c(VIcon,[_vm._v(_vm._s(item.active ? 'mdi-account-lock' : 'mdi-account'))])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }