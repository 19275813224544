<template>
  <v-select
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      dense
      class="text-body-2 px-2"
      persistent-hint
      :disabled="disabled"
      :items="items"
      :rules="rules"
  />
</template>

<script>
export default {
  name: "AppSelect",
  props: ["disabled","items","rules"],
}
</script>

<style scoped>

</style>